import {Injectable} from '@angular/core';
import {ApiUrls} from '../../configs/api-urls.config';
import {map} from 'rxjs/operators';
import {PaginationProperties} from '../../models/pagination-properties';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class VehicleTripService {

  constructor(private _http: HttpClient) {
  }

  create(model) {
    model.start.setHours(model.startTime.hour);
    model.start.setMinutes(model.startTime.minute);
    if (model.end && model.endTime && model.endTime.hour && model.endTime.minute) {
      model.end.setHours(model.endTime.hour);
      model.end.setMinutes(model.endTime.minute);
    }
    return this._http.post(ApiUrls.API_CREATE_VEHICLE_TRIP, model).pipe(map(res => {
      return res;
    }));
  }

  finishTrip(model) {
    model.start.setHours(model.startTime.hour);
    model.start.setMinutes(model.startTime.minute);
    model.end.setHours(model.endTime.hour);
    model.end.setMinutes(model.endTime.minute);
    return this._http.post(ApiUrls.API_FINISH_VEHICLE_TRIP, model).pipe(map(res => {
      return res;
    }));
  }


  getVehicleLastTrip(vehicleId) {
    return this._http.get(ApiUrls.API_GET_LAST_TRIP + '?vehicleId=' + vehicleId).pipe(map(res => {
      return res;
    }));
  }

  getUserTrips(paginationProperties: PaginationProperties) {
    return this._http.post(ApiUrls.API_PAGINATION_USER_TRIPS, paginationProperties).pipe(map(res => {
      return res;
    }));
  }
  getTripRefill(data: any, options: any){
    return this._http.post(ApiUrls.API_TRIP_REFILL, data, options).pipe(map(res => {
      return res;
    }));
  }
  get(paginationProperties: PaginationProperties) {
    return this._http.post(ApiUrls.API_PAGINATION_VEHICLE_TRIP, paginationProperties).pipe(map(res => {
      return res;
    }));
  }

  delete(Id) {
    return this._http.delete(ApiUrls.API_DELETE_VEHICLE_TRIP + '?id=' + Id).pipe(map(res => {
      return res;
    }));
  }
}

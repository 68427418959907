import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DynamicConfirmationDialogData} from './DynamicConfirmationDialogData';

@Component({
  selector: 'dynamic-confirmation-dialog',
  templateUrl: './dynamic-confirmation-dialog.component.html',
  styleUrls: ['./dynamic-confirmation-dialog.component.scss']
})
export class DynamicConfirmationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DynamicConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DynamicConfirmationDialogData,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmitClick(): void {
    this.dialogRef.close('submit');
  }
}

import {Component, Input, OnInit, OnDestroy} from '@angular/core';

import {NbMenuService, NbSidebarService} from '@nebular/theme';
import {AnalyticsService} from '../../../@core/utils/analytics.service';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {TranslateService} from '@ngx-translate/core';
import {filter, map, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {VehicleExpired} from '../../../models/vehicles/vehicle-expired.model';
import {Subject} from 'rxjs';
import {VehicleExpiredService} from '../../../Services/vehicle/vehicle-expired.service';
import {AppSettings} from "../../../configs/app-settings.config";
import {AuthenticationService} from "../../../Services/authentication/authentication.service";


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() position = 'normal';
  private onDestroy$ = new Subject<boolean>();
  user: any;
  isAuthenticated = false;
  ExpiredVehicle: VehicleExpired[] = [];
  userMenu = [{title: 'Log out'}, {title: 'Change password'}];
  isAdmin: boolean = false;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private analyticsService: AnalyticsService,
              private authService: NbAuthService,
              private authenticationService: AuthenticationService,
              private translate: TranslateService,
              private router: Router,
              private vehicleExpiredService: VehicleExpiredService,
  ) {
    this.userMenu = [{title: this.translate.instant('Menu.Account.ChangePassword')},
      {title: this.translate.instant('Menu.Account.Logout')}];
    this.vehicleExpiredService.SubjectForExpiredVehicle
      .pipe(
        takeUntil(this.onDestroy$),
      )
      .subscribe((res: VehicleExpired[]) => {
        //console.log("re");
        this.ExpiredVehicle = res;
      });
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        //console.log('TOKEN CHANGED');
        this.isAuthenticated = false;
        if (token.isValid()) {
          this.isAuthenticated = true;
          this.user = token.getPayload(); // here we receive a payload from the token and assigne it to our `user` variable
        }
      });
    this.checkRole();
  }

  checkRole() {
    this.authenticationService.getRoles().subscribe(roles => {
      this.isAdmin = roles.includes(AppSettings.ADMIN);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
  }

  ngOnInit() {
    this.menuService.onItemClick()
      .pipe(
        filter(({tag}) => tag === 'user-context-menu'),
        map(({item: {title}}) => title),
      )
      .subscribe(title => {
          if (title === this.translate.instant('Menu.Account.Logout')) {
            this.router.navigateByUrl('/auth/logout');
          } else if (title === this.translate.instant('Menu.Account.Profile')) {
            this.router.navigateByUrl('/pages/profile')
          } else if (title === this.translate.instant('Menu.Account.ChangePassword')) {
            this.router.navigateByUrl('/pages/profile/change-password')
          }
        }
      )
    ;
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  // toggleSettings(): boolean {
  //   this.sidebarService.toggle(false, 'settings-sidebar');
  //
  //   return false;
  // }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
}

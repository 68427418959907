import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent {
  @Input() icon = "arrow";
  @Input() backgroundColor: '#4791F1' | '#44EB5E' = '#4791F1';
  @Input() title: string = ''
  @Input() warning: number = 0;
  @Input() orange: number = 0;
  @Input() danger: number = 0;
  collapse: boolean = true;

  constructor() { }

  toggle() {
    this.collapse = !this.collapse;
  }
}

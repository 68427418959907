import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiUrls } from '../../configs/api-urls.config';
import { VehicleExpired } from '../../models/vehicles/vehicle-expired.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VehicleExpiredService {
SubjectForExpiredVehicle = new BehaviorSubject(null);

constructor(private _http: HttpClient) { }

checkExpiration() {
  return this._http.get(ApiUrls.API_CHECK_VEHICLE_EXPIRATION).pipe(
               map((res: VehicleExpired[]) => {
                this.SubjectForExpiredVehicle.next(res);
              })).subscribe();
  }
}

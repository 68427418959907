export enum ActivityType {
  GENERAL = 1,
  EQUIPMENTS,
  MEDICINE,
  TABLET,
  ROUTINE,
  DIFFUSER,
  CUSTOM,
  Vehicle,
  Drugs
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PaginationProperties} from '../../models/pagination-properties';
import {ApiUrls} from '../../configs/api-urls.config';
import {map} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private notificationCountSubject = new BehaviorSubject<number>(0);
  private notificationsSubject = new BehaviorSubject<any[]>([]);

  notificationCount$ = this.notificationCountSubject.asObservable();
  notifications$ = this.notificationsSubject.asObservable();

  constructor(private _http: HttpClient) {}
  incrementCount() {
    this.notificationCountSubject.next(this.notificationCountSubject.value + 1);
  }
 resetCount(){
  this.notificationCountSubject.next(0)
 }

 addNotifications() {
  return this.notificationsSubject.asObservable();
}
addNewNotification(notification: any) {
  const notifications = this.notificationsSubject.value;
  notifications.push(notification);
  this.notificationsSubject.next(notifications);
  this.incrementCount();
}
  getNotifications(paginationProperties: PaginationProperties) {
    return this._http.post(ApiUrls.API_GET_NOTIFICATIONS, paginationProperties).pipe(map(res => {
      return res;
    }));
  }

  resolveNotification(resolveModel) {
    return this._http.post(ApiUrls.API_NOTIFICATION_RESOLVE, resolveModel)
      .pipe(map(res => {
        return res;
      }));
  }

}

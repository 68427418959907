export class DynamicConfirmationDialogData {
  body?: string;
  confirm?: string;
  add?: string;
  confirmColor?: string;
  item?: {
    litersBought?: number;
    tankingKM?: number;
    vehicle?: {
      tankCapacity?: number;
    };
    tripId?: number; // Add this property to the interface
  };
  header?: string;
  cancel?: string;
  recordName?: string;
  registrationNumber?: string;
  vehicleSymbol?: string;
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IListItemIcon } from "../../interfaces/list-item-icon.interface";
import { PaginationProperties } from "../../models/pagination-properties";
import { ApiUrls } from "../../configs/api-urls.config";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { PrescriptionStatusEnum } from "../../pages/prescriptions/Enum-Prescriptions/prescription-status-enum";
import { UpdatePrescriptionStatusModel } from "../../pages/prescriptions/Enum-Prescriptions/update-prescriptions-model-enum";
import { PrescriptionModel } from "../../pages/prescriptions/prescription-model/prescription-model";

@Injectable({
  providedIn: "root",
})
export class PrescriptionsService {
  constructor(private _http: HttpClient) {}
  public OcCardsStatus: IListItemIcon[] = [
    {
      value: PrescriptionStatusEnum.Pending,
      text: "Pending",
      color: "#F99500",
      icon: "fa-exclamation-triangle",
    },
    {
      value: PrescriptionStatusEnum.Issue,
      text: "Issue",
      color: "#0000FF",
      icon: "fa-check-circle",
    },
    {
      value: PrescriptionStatusEnum.Dispense,
      text: "Dispense",
      color: "#44EB5E",
      icon: "fa-check-circle",
    },
  ];

  pagination(paginationProperties: PaginationProperties): any {
    return this._http
      .post(ApiUrls.API_PRESCRIPTION_PAGINATION, paginationProperties)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  create(model: PrescriptionModel) {
    return this._http.post(ApiUrls.API_CREATE_PRESCRIPTION, model).pipe(
      map((res) => {
        return res;
      })
    );
  }
  GetPendingCount() {
    return this._http
      .get(ApiUrls.API_PRESCRIPTION_GET_PENDING_COUNT)
      .pipe(
        map((res) => {
          setTimeout(() => {
            document.querySelectorAll(".menu-title").forEach((x: any) => {
              if (x.innerText.includes("Prescriptions")) {
                let html = "";
                if (res)
                  html += `<span class="custom-badge badge-danger danger-problem mr-1">${res}</span>`;
                x.innerHTML = `Prescriptions${html}`;
              }
            });
          }, 3000);
          return res;
        })
      )
      .subscribe();
  }

  upDateStatus(status: UpdatePrescriptionStatusModel): Observable<any> {
    return this._http.post(ApiUrls.API_PRESCRIPTION_UPDATE_STATUS, status).pipe(
      map((res) => {
        return res;
      })
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiUrls } from '../../../configs/api-urls.config';
import { IListItemIcon } from '../../../interfaces/list-item-icon.interface';
import { IListItem } from '../../../interfaces/list-item.interface';
import { PaginationProperties } from '../../../models/pagination-properties';
import { ActivityType } from '../../../models/vehicles/oc-card/activity-type.enum';
import { ProblemStatus } from '../../../models/vehicles/oc-card/problem-status.enum';

@Injectable({
  providedIn: 'root'
})
export class ProblemsService {
  public ProblemsStatus: IListItemIcon[] = [
    {value: ProblemStatus.CANCELLED, text: 'Cancelled', color: '#878E9A', icon: 'fa-times-circle'},
    {value: ProblemStatus.WAITING, text: 'Waiting', color: '#F99500', icon: 'fa-exclamation-triangle'},
    {value: ProblemStatus.FIXED, text: 'Solved', color: '#44EB5E', icon: 'fa-check-circle'}
  ];
  public ActivityTypeList: IListItem[] = [
    {value: ActivityType.GENERAL, text: 'General'},
    {value: ActivityType.EQUIPMENTS, text: 'Equipments'},
    {value: ActivityType.MEDICINE, text: 'Medicine'},
    {value: ActivityType.TABLET, text: 'Tablet'},
    {value: ActivityType.ROUTINE, text: 'Routine'},
    {value: ActivityType.DIFFUSER, text: 'Diffuser'},
    {value: ActivityType.CUSTOM, text: 'Custom'},
    {value: ActivityType.Vehicle, text: 'Vehicle'},
    {value: ActivityType.Drugs, text: 'Drugs'}
  ];
  constructor(private _http: HttpClient) {
  }
  getProblems(paginationProperties: PaginationProperties) {
    return this._http.post(ApiUrls.API_PAGINATION_PROBLEMS, paginationProperties).pipe(map(res => {
      return res;
    }));
  }
  changeStatus(model: any) {
    return this._http.post(ApiUrls.API_UPDATE_PROBLEM, model).pipe(map(res => {
      return res;
    }));
  }
  getWaitingCount() {
    return this._http.get(ApiUrls.API_GET_WAITING_PROBLEM).pipe(map(res => {
      setTimeout(() => {
        document.querySelectorAll('.menu-title').forEach((x: any) => {
          if(x.innerText.includes('Karty OC')) {
            let html = '';
            if(res[0]) html = `<span class="custom-badge badge-warning rounded-circle mr-1">${res[0]}</span>`;
            if(res[1]) html += `<span class="custom-badge badge-orange mr-1">${res[1]}</span>`
            if(res[2]) html += `<span class="custom-badge badge-danger danger-problem mr-1">${res[2]}</span>`
            x.innerHTML = `Karty OC ${html}`
          }
        });
      }, 3000);
      return res;
    })).subscribe();
  }
  
  GetAttachments(id) {
    return this._http.get(ApiUrls.API_GET_ALL_Attachments + '?id=' + id).pipe(map(res => {
      return res;
    }));
  }

}

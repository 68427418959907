export class AppSettings {

  public static readonly APP_NAME = 'Pogotowie';

  public static readonly LANDING_PAGE_URL_LOGGEDIN = '/pages/dashboard';
  // User List Type by

  public static readonly ROLE = 'ROLE';
  public static readonly TEAM = 'TEAM';

  // User roles
  public static readonly ADMIN = 'Admin';
  public static readonly DOCTOR = 'Doctor';
  public static readonly DRIVER = 'Driver';
  public static readonly PARAMEDIC = 'Paramedic';
  public static readonly WORKER = 'Worker';
  public static readonly GUEST = 'Guest';
  public static readonly LEADER = 'Leader';
  public static readonly NURSE = 'Nurse';
  public static readonly COORDINATOR = 'Coordinator';
  public static readonly TECHNICALCOORDINATOR = 'TechnicalCoordinator';


  // Contract types
  public static readonly MEDICAL= 'Umowa o pracę';
  public static readonly BASIC= 'Umowa zlecenie/kontrakt';


  // CheckupTypes
  public static readonly PRELIMINARY= 'Wstępne';
  public static readonly PERIODIC= 'Okresowe';
  public static readonly INSPECTION= 'Kontrolne';


  public static readonly JWTRoles = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

  public static readonly CURRENT_USER_KEY = 'CurrentUserKey';

  public static readonly FuelTypes: [
    {
      Text: 'ON',
      Value: 1
    },
    {
      Text: 'PB',
      Value: 2
    }
    ];

  public static readonly InsurancePolicyTypes: [
    {
      Text: 'OC',
      Value: 1,
    },
    {
      Text: 'AC',
      Value: 2,
    }
    ];

  // User preferences
  public static readonly USER_LANGUAGE = 'UserLanguage';
}

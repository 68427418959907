import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material';
import {ApiUrls} from '../../configs/api-urls.config';
import {Router} from '@angular/router';
import {NbAuthService} from '@nebular/auth';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private nbAuth: NbAuthService,
              private snackBar: MatSnackBar,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 && request.url !== ApiUrls.API_LOGIN) {
        // auto logout if 401 response returned from api
        this.nbAuth.logout('email').first().subscribe(res => {
        });
        this.router.navigate(['/user/login']);
      }
      if (err.status === 403 && request.url !== ApiUrls.API_LOGIN) {
        // auto logout if 403 response returned from api
        this.nbAuth.logout('email').first().subscribe(res => {
        });
        this.router.navigate(['/user/login']);
      }

      if (err.status === 422) {
        this.showErrors(err, request);
      }
      return throwError(err);
    }));
  }


  private showErrors(err: any, request: HttpRequest<any>) {
    //console.log(err.error);
    try {
      if (err.error && Array.isArray(err.error)) {
        err.error.forEach(error => {
          const keys = Object.keys(error);
          this.snackBar.open('Błąd: ' + error [keys[keys.length - 1]], 'OK');
        });
      } else if (typeof err.error === 'string' || err.error instanceof String) {
        this.snackBar.open('Błąd: ' + err.error, 'OK');
      }
    } catch (e) {
      //console.log('Unexpected 422 ERROR in: ' + request.urlWithParams, err);
    }
  }


}
